<template>
  <div class="overHome">
    <div class="exhibition"
         v-html="content"
         @click="showImg($event)">
    </div>
    <!-- 富文本图片放大 -->
    <van-image-preview v-model="imgPreviewShow"
                       :images="images">
    </van-image-preview>
  </div>
</template>

<script>
import {
  getCloudNoticeListData,
} from "@/api/index";
export default {
  data () {
    return {
      content: '',
      imgPreviewShow: false,
      images: [],
    }
  },
  created () {
    this.initTbel();
  },
  methods: {
    showImg (e) {
      // console.log(e.target)
      if (e.target.tagName == 'IMG') {
        this.images = [e.target.src]
        this.imgPreviewShow = true
      }
    },
    initTbel () {
      let datas = {
        key: 'exhi_overview'
      }
      getCloudNoticeListData(datas).then(res => {
        this.content = res.data.value;
      })
    }
  }
}
</script>

<style scoped lang="less">
.overHome {
  background: #fff !important;
  height: 100%;
  overflow: hidden;
}
.exhibition {
  margin: 0 auto;
  text-align: left;
  padding: 10px;
  ::v-deep {
    p {
      margin: 0 0 16px 0;
      &:last-child {
        padding: 0 10px !important;
        line-height: 25px;
      }
      img {
        width: 100%;
      }
    }
  }
}
</style>
